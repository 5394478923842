import axios from "axios";
import qs from "qs";
import CryptoJS from "crypto-js";
const SecretId = "APIDgX3rtzcK7EiUnKRocann1H8AgR7ia5tbc9Q";
const SecretKey = "J866v838MxC3zfs6PTg7kkAFbvwtx43w51Yo94I";

function getHeader(data) {
  let dateTime = new Date().toUTCString();
  let contentMD5 = "";
  const headerObj = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded", 
    token: localStorage.getItem("token"),
  };
  let headers = {
    ...headerObj,
    ...{ "Content-MD5": contentMD5, "x-date": dateTime },
    ...(data.headers || {})
  };
  let path = data.url;
  if (data.hasOwnProperty("data")) {
    let param = getParams(typeof data.data === "string" ? qs.parse(data.data) : data.data);
    if (param.length > 0) path = path + "?" + param;
  }
  let signing = [
    `x-date: ${dateTime}`,
    data.method.toUpperCase(),
    headers.Accept,
    data.method.toUpperCase()=="GET" ? "":   headers["Content-Type"],
    contentMD5,
    path,
  ];
  headers.Authorization = getSign(signing);
  return headers;
}
function getParams(data) {
  if (!data) return "";

  let res = urlEncode({ ...data });
  if (res.length > 0) {
    res = res.substring(1, res.length);
    let arr = res.split("&");
    arr = arr.sort();
    return arr.join("&");
  }

  return res;
}
function urlEncode(param, key, encode) {
  if (param == null) return "";
  var paramStr = "";
  var t = typeof param;
  if (t == "string" || t == "number" || t == "boolean") {
    if (param.toString() == "") {
      paramStr += "&" + key;
    } else {
      paramStr += "&" + key + "=" + param;
    }
  } else {
    for (var i in param) {
      var k = key == null ? i : key + "[" + i + "]";
      paramStr += urlEncode(param[i], k, encode);
      
    }
  }
  return paramStr;
}
function getSign(signarr) {
  let signingStr = signarr.join("\n");
  // console.log(signingStr)
  const signing = CryptoJS.enc.Base64.stringify(
    CryptoJS.HmacSHA1(signingStr, SecretKey)
  );
  const sign = `hmac id="${SecretId}", algorithm="hmac-sha1", headers="x-date", signature="${signing}"`;
  return sign;

}

//请求截拦
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断vuex中是否存在token，token需要和后台商量好
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    if (config.method === "get") {
      if (!config.params) {
        // config.params =  { value: "1" };
      }
      config.data = config.params; // 这个是关键点，加入这行就可以了,解决get,请求添加不上Content-Type
    }
    let headerObj = getHeader(config);
    config.headers = headerObj;
    return config;
  },
  (error) => {
    return error;
  }
);

//数据拿到之后
axios.interceptors.response.use(
  (response) => {
    // console.log("response", response)
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  },
  (error) => {
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    /*if (error.response.status === 401) {
        localStorage.removeItem('token')
        vm.$root.$emit('login',1)
    } else {
        return error.response;
    }*/
    return error.response;
  }
);

export function GET(url, params, baseUrl) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      baseURL: baseUrl,
      url,
      params,
      // timeout:15000,//响应时间
      timeout: 180000, //响应时间
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function POST(url, params, baseUrl) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      baseURL: baseUrl,
      url,
      headers: {
        "Accept":"application/json",
        "Content-Type": "application/x-www-form-urlencoded", 
      },
      data: qs.stringify(params),
      // timeout:5000,//响应时间
      timeout: 180000, //响应时间
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function POST2(url, params, baseUrl) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      baseURL: baseUrl,
      headers: {
        "Content-Type": "multipart/form-data",
        fileName: "fileName",
      },
      url,
      data: qs.stringify(params),
      timeout: 5000, //响应时间
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function POST3(url, params, baseUrl) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      baseURL: baseUrl,
      headers: {
        "Content-Type": "application/json",
      },
      url,
      data: params,
      timeout: 60000, //响应时间
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
