import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/css/swiper.css'
import api from '@/api/api'

Vue.prototype.$api = api
Vue.config.productionTip = false;
Vue.use(ElementUI);
import {message} from '../src/utils/resetMessage';
Vue.prototype.$message = message;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
